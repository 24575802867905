import { useAuthInfo } from '@propelauth/react';
import { Tokens } from '@propelauth/react/dist/types/AuthContext';

const setToken = async (tokens: Tokens, orgId: string) => {
	const accessToken = await tokens.getAccessTokenForOrg(orgId);
	localStorage.setItem('authToken', accessToken.accessToken);
};

export const AuthToLocalStorage = () => {
	const { tokens, orgHelper } = useAuthInfo();

	const activeOrgId = localStorage.getItem('activeOrgId');
	if (!activeOrgId) throw new Error('No active org id');

	const activeOrg = orgHelper?.getOrg(activeOrgId);
	if (!activeOrg) throw new Error('No active org');

	setToken(tokens, activeOrg.orgId);

	return null;
};
