import { create } from 'zustand';

export type BreadcrumbItem = {
	name: string;
	href?: string;
};

type BreadcrumbState = {
	items: BreadcrumbItem[];
	setBreadcrumb: (items: BreadcrumbItem[]) => void;
};

export const useBreadcrumbState = create<BreadcrumbState>()((set) => ({
	items: [],
	setBreadcrumb: (items) => set(() => ({ items })),
}));
