import { useAuthInfo, useLogoutFunction, useRedirectFunctions } from '@propelauth/react';
import { LogOut, ShieldCheck } from 'lucide-react';
import { LuBuilding } from 'react-icons/lu';

import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar';
import { Button } from '@/components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

export default function UserMenu() {
	const { user, orgHelper } = useAuthInfo();

	const { redirectToAccountPage, redirectToOrgPage } = useRedirectFunctions();
	const logout = useLogoutFunction();
	const activeOrg = orgHelper?.getOrg(localStorage.getItem('activeOrgId')!);

	return (
		<DropdownMenu>
			<DropdownMenuTrigger asChild>
				<Button variant="ghost" className="relative flex gap-x-4 rounded-full px-0 hover:bg-blue-800">
					<Avatar className="rounded-full border border-white">
						<AvatarImage src={activeOrg?.orgMetadata?.icon || user?.pictureUrl} />
						<AvatarFallback>{activeOrg?.orgMetadata?.name}</AvatarFallback>
					</Avatar>
				</Button>
			</DropdownMenuTrigger>
			<DropdownMenuContent className="w-56" align="end">
				<DropdownMenuItem onClick={() => redirectToOrgPage()}>
					<LuBuilding />
					<span>Organization Authentication</span>
				</DropdownMenuItem>
				{/* <Link to={urlForUserSettings()}>
					<DropdownMenuItem>
						<User className="h-4 w-4" />
						<span>User settings</span>
					</DropdownMenuItem>
				</Link> */}
				<DropdownMenuItem onClick={() => redirectToAccountPage()}>
					<ShieldCheck className="h-4 w-4" />
					<span>Login Settings</span>
				</DropdownMenuItem>
				<DropdownMenuSeparator />
				<DropdownMenuItem onClick={() => logout(true)}>
					<LogOut className="h-4 w-4" />
					<span>Logout</span>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
}
