import { Button } from '@/components/ui/button';

interface ErrorComponentProps {
	error?: Error;
	statusCode?: number;
	reset?: () => void;
}

export default function ErrorComponent({
	error,
	statusCode = 500,
	reset = () => window.location.reload(),
}: ErrorComponentProps = {}) {
	return (
		<div className="min-h-screen bg-background flex flex-col items-center justify-center text-foreground">
			<div className="text-center space-y-4">
				<h1 className="text-4xl font-bold">{statusCode ? `${statusCode}: ` : ''}An error occurred</h1>
				{error && <p className="text-xl text-muted-foreground">{error.message || 'Something went wrong'}</p>}
				<div>
					<Button onClick={reset} variant="outline" className="mt-4">
						Try again
					</Button>
				</div>
			</div>
		</div>
	);
}
