import BaseConfetti from 'react-confetti';
import useWindowSize from 'react-use/lib/useWindowSize';
import { create } from 'zustand';

type ConfettiState = {
	isConfetti: boolean;
	startConfetti: () => void;
	confettiComplete: () => void;
};

export const useConfettiStore = create<ConfettiState>()((set) => ({
	isConfetti: false,
	startConfetti: () => set({ isConfetti: true }),
	confettiComplete: () => set({ isConfetti: false }),
}));

export const Confetti = () => {
	const { width, height } = useWindowSize();
	const isConfetti = useConfettiStore((state) => state.isConfetti);
	const confettiComplete = useConfettiStore((state) => state.confettiComplete);

	return (
		isConfetti && (
			<BaseConfetti width={width} height={height} recycle={false} onConfettiComplete={confettiComplete} />
		)
	);
};
