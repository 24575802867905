import { useAuthInfo } from '@propelauth/react';

import { Badge } from './ui/badge';

export const ImpersonatingBadge = () => {
	const { userClass } = useAuthInfo();
	if (!userClass?.isImpersonating()) return null;

	return <Badge variant="success">Impersonating</Badge>;
};
