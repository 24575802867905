import Intercom from '@intercom/messenger-js-sdk';
import { useAuthInfo } from '@propelauth/react';
import { useEffect } from 'react';

const APP_ID = 'rk6mq5jq';

export const IntercomMessenger = () => {
	const { user, userClass, orgHelper } = useAuthInfo();
	const activeOrgId = localStorage.getItem('activeOrgId');

	useEffect(() => {
		// exit if not ready or impersonating
		if (import.meta.env.DEV) return;
		if (!user || !activeOrgId) return;
		if (userClass && userClass.isImpersonating()) return;

		const activeOrg = orgHelper?.getOrg(activeOrgId);
		if (!activeOrg) throw new Error('Org not found');

		// init Intercom
		Intercom({
			app_id: APP_ID,
			user_id: user.userId,
			name: `${user.firstName} ${user.lastName}`,
			email: user.email,
			created_at: user.createdAt,
			user_hash: (user.properties as any)?.metadata?.intercomHash,
			company: {
				id: activeOrg.orgId,
				name: activeOrg.orgName,
			},
		});
	}, [user, userClass, orgHelper, activeOrgId]);

	return null;
};
