import { Link } from '@tanstack/react-router';
import { Fragment } from 'react';

import { useBreadcrumbState } from '@/hooks/useBreadcrumb';

import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList, BreadcrumbSeparator } from './ui/breadcrumb';

export const Breadcrumbs = () => {
	const { items } = useBreadcrumbState();
	return (
		<Breadcrumb>
			<BreadcrumbList>
				<BreadcrumbItem key="root">
					<BreadcrumbLink asChild>
						<Link to="/" className="">
							<img src="/logo.svg" className="size-10" alt="SideDish" width={36} height={36} />
						</Link>
					</BreadcrumbLink>
				</BreadcrumbItem>
				{items.map((item, index) => (
					<Fragment key={index}>
						{index !== 0 && <BreadcrumbSeparator />}
						<BreadcrumbItem>
							{item.href ? (
								<BreadcrumbLink asChild>
									<Link to={item.href} className={index === items.length - 1 ? 'font-semibold' : ''}>
										{item.name}
									</Link>
								</BreadcrumbLink>
							) : (
								<span className={index === items.length - 1 ? 'font-semibold' : ''}>{item.name}</span>
							)}
						</BreadcrumbItem>
					</Fragment>
				))}
			</BreadcrumbList>
		</Breadcrumb>
	);
};
