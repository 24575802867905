import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { z } from 'zod';

export type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export const revalidateOnceEveryMinute = { next: { revalidate: 60 } };
export const revalidateOnceEvery5Minutes = { next: { revalidate: 60 * 5 } };
export const revalidateOnceEvery10Minutes = { next: { revalidate: 60 * 10 } };
export const revalidateOnceEveryHour = { next: { revalidate: 60 * 60 } };
export const revalidateOnceADay = { next: { revalidate: 60 * 60 * 24 } };

export function cn(...inputs: ClassValue[]) {
	return twMerge(clsx(inputs));
}

export function formatFileSize(bytes: number): string {
	// Define thresholds for KB and MB
	const KB = 1024;
	const MB = 1024 * KB;

	// Format and return the file size in KB or MB
	if (bytes >= MB) {
		return `${(bytes / MB).toFixed(2)} MB`;
	} else if (bytes >= KB) {
		return `${(bytes / KB).toFixed(2)} KB`;
	} else {
		return `${bytes} bytes`;
	}
}

export function sleep(time: number) {
	return new Promise((resolve) => setTimeout(resolve, time));
}

export type IdNameMap = {
	id: string;
	name: string;
};
export function convertIdsToNames(ids: string[], map: IdNameMap[]): string[] {
	return ids.map((id) => map.find((item) => item.id === id)?.name || id);
}

/** Schema for a string that has to be either a valid url or empty */
export const urlOrEmptySchema = z
	.string()
	.url()
	.nullish()
	.transform((v) => (v === '' ? null : v));

export const pick = <T extends {}, K extends keyof T>(obj: T, ...keys: K[]) =>
	Object.fromEntries(keys.filter((key) => key in obj).map((key) => [key, obj[key]])) as Pick<T, K>;

export const createSelectInputOptionsFromArray = (arr: string[], labels: Record<string, string>) =>
	arr.map((status: string) => ({
		label: labels[status],
		value: status,
	}));

export const queryParamsToString = (queryParams: Record<string, any> | undefined) => {
	if (!queryParams) return '';
	const params = new URLSearchParams();

	Object.entries(queryParams).forEach(([key, value]) => {
		if (value !== undefined) {
			params.append(key, String(value));
		}
	});

	return params.toString();
};
