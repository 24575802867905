import { showNewMessage } from '@intercom/messenger-js-sdk';
import { BiSupport } from 'react-icons/bi';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { MdOutlineFeedback } from 'react-icons/md';

import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuItem,
	DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';

export const SupportMenu = () => {
	return (
		<DropdownMenu>
			<DropdownMenuTrigger className="text-sm">Support & Feedback</DropdownMenuTrigger>
			<DropdownMenuContent className="w-64">
				<a href="https://sidedish.mintlify.app" target="_blank">
					<DropdownMenuItem>
						<IoDocumentTextOutline />
						<span>Docs</span>
					</DropdownMenuItem>
				</a>
				<DropdownMenuItem onClick={() => showNewMessage('I have feedback:\n')}>
					<MdOutlineFeedback />
					<span>Feedback & Feature requests</span>
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => showNewMessage('')}>
					<BiSupport />
					<span>Support</span>
				</DropdownMenuItem>
				<DropdownMenuItem onClick={() => showNewMessage('I have a technical question:\n')}>
					<BiSupport />
					<span>Talk to an engineer</span>
				</DropdownMenuItem>
			</DropdownMenuContent>
		</DropdownMenu>
	);
};
