import { RequiredAuthProvider, useAuthInfo } from '@propelauth/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { RouterProvider } from '@tanstack/react-router';
import { toast } from 'sonner';

import { router } from './router';

// Create a client
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {},
		mutations: {
			onError: (error) => {
				toast.error(error.message);
				console.error(error);
			},
		},
	},
});

export function RouterWithAuth() {
	const { orgHelper } = useAuthInfo();
	let activeOrgId = localStorage.getItem('activeOrgId');

	const authOrgs = orgHelper?.getOrgs();
	if (authOrgs?.length === 1) {
		activeOrgId = authOrgs[0].orgId;
		localStorage.setItem('activeOrgId', activeOrgId);
	}

	return <RouterProvider router={router} />;
}

export function App() {
	return (
		<RequiredAuthProvider authUrl={import.meta.env.NEXT_PUBLIC_AUTH_URL!}>
			<QueryClientProvider client={queryClient}>
				<RouterProvider router={router} />
			</QueryClientProvider>
		</RequiredAuthProvider>
	);
}
