import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Outlet, createRootRoute, redirect } from '@tanstack/react-router';
import React, { Suspense } from 'react';

import { NotFound } from '@/components/404';
//import Analytics from '@/components/Analytics';
import { AuthToLocalStorage } from '@/components/AuthToLocalStorage';
import { Confetti } from '@/components/Confetti';
import ErrorComponent from '@/components/ErrorComponent';
import { Header } from '@/components/Header';
import { IntercomMessenger } from '@/components/Intercom';
import { Toaster } from '@/components/ui/sonner';

export const Route = createRootRoute({
	component: RootComponent,
	notFoundComponent: NotFound,
	errorComponent: ErrorComponent,
	beforeLoad: ({ location }) => {
		const activeOrgId = localStorage.getItem('activeOrgId');
		if (!activeOrgId && location.pathname !== '/select-project') {
			throw redirect({ to: '/select-project' });
		}
	},
});

const TanStackRouterDevtools = import.meta.env.PROD
	? () => null // Render nothing in production
	: React.lazy(() =>
			// Lazy load in development
			import('@tanstack/router-devtools').then((res) => ({
				default: res.TanStackRouterDevtools,
				// For Embedded Mode
				// default: res.TanStackRouterDevtoolsPanel
			})),
		);

function RootComponent() {
	return (
		<div>
			<Header />

			<main className="mt-12 w-full p-8">
				<Outlet />
			</main>

			<Toaster richColors />
			<Confetti />
			<AuthToLocalStorage />
			{/* <Analytics /> */}
			<IntercomMessenger />
			<ReactQueryDevtools initialIsOpen={false} />
			<Suspense>
				<TanStackRouterDevtools position="bottom-right" />
			</Suspense>
		</div>
	);
}
