import { Breadcrumbs } from '@/components/Breadcrumbs';
import { ImpersonatingBadge } from '@/components/ImpersonatingBadge';
import { SupportMenu } from '@/components/SupportMenu';
import UserMenu from '@/components/UserMenu';

export const Header = () => {
	return (
		<header className="fixed inset-x-0 top-0 z-50 flex flex-wrap border-b border-gray-200 bg-white dark:border-neutral-700 dark:bg-neutral-800 md:flex-nowrap md:justify-start">
			<div className="flex w-full gap-8 px-4 py-2.5 sm:px-6 lg:px-8">
				<div className="flex flex-1 items-center gap-x-3">
					<Breadcrumbs />
				</div>

				<ImpersonatingBadge />
				<SupportMenu />

				<UserMenu />
			</div>
		</header>
	);
};
