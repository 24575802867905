import { Link } from '@tanstack/react-router';

export const NotFound = () => {
	return (
		<div className="py-32 flex flex-col justify-center items-center">
			<div className="text-center">
				<h1 className="text-xl font-extrabold text-gray-800">404</h1>
				<h2 className="text-2xl font-bold text-gray-700 mt-4">Page Not Found</h2>
				<p className="text-gray-600 mt-4">Sorry, we couldn't find the page you're looking for.</p>
				<Link
					to="/"
					className="mt-8 inline-block px-6 py-3 bg-blue-600 text-white font-semibold rounded-md hover:bg-blue-700 transition duration-300 ease-in-out"
				>
					Go back home
				</Link>
			</div>
		</div>
	);
};
